import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';

import IconSend from './icons/IconSend';

const Main = styled.div`
  display: grid;
  gap: 8px;
  align-items: center;
  grid-template-columns: minmax(0, max-content) max-content;
  width: 100%;

  padding: 16px 18px;
  border-radius: 60px;
  border: 1px solid ${colorsV2.gray20};
  background: ${colorsV2.white100};
  cursor: pointer;

  .icon-wrapper {
    width: 20px;
    height: 20px;
  }
  &:hover {
    border: 1px solid ${colorsV2.primary100};
    .icon-wrapper {
      > svg {
        path {
          fill: ${colorsV2.primary100};
        }
      }
    }

    > p {
      color: ${colorsV2.primary100};
    }
  }

  &.active {
    border: 1px solid ${colorsV2.primary100};
    background-color: ${colorsV2.primary10};
    .icon-wrapper {
      > svg {
        path {
          fill: ${colorsV2.primary100};
        }
      }
    }

    > p {
      color: ${colorsV2.primary100};
    }
  }
`;

const CourseButton = ({ content, active = false, ...rest }) => {
  return (
    <Main className={cl('course-button', { active })} {...rest}>
      <div className="icon-wrapper">
        <IconSend color={colorsV2.black80} />
      </div>
      <Typography className="course-button-text" variant="semi-bold/20-28">
        {content}
      </Typography>
    </Main>
  );
};

CourseButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired
};

CourseButton.defaultProps = {};

export default React.memo(CourseButton);
