import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@dolstaff/shared/es/Button';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { getAssetLinkByHandle } from 'utils/getAssetLink';
import { LazyImage } from 'components/LazyImage';
import IconCheckmark from './icons/IconCheckmark';

import { fromScreen, useFromScreens } from 'utils/media-query/responsive.util';

const headerDescription = [
  'Linearthinking - Học TA tư duy và dễ dàng hơn',
  'Đội ngũ giáo viên giỏi và tận tâm',
  'Hệ thống công nghệ riêng và xịn sò',
  'Chuyên IELTS/SAT/TOEIC với Linearthinking giúp tăng điểm thần tốc'
];

const ImgWithSkeleton = props => {
  const { imgHandle, alt } = props;
  return (
    <div className="banner">
      <img
        loading="eager"
        src={getAssetLinkByHandle({ handle: imgHandle })}
        alt={alt}
      />
    </div>
  );
};

const DescriptionItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  margin-bottom: 8px;

  svg {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }

  p {
    margin-left: 8px;
  }
`;
const DescriptionItem = ({ description }) => {
  return (
    <DescriptionItemWrapper>
      <IconCheckmark />
      <Typography
        variant="regular/16-24"
        desktopVariant="regular/20-28"
        color={colorsV2.black80}
        v3
      >
        {description}
      </Typography>
    </DescriptionItemWrapper>
  );
};

const BannerWrapper = styled.div`
  display: none;

  ${fromScreen(776)} {
    > div {
      max-height: 166px;
    }
    display: grid;
    gap: 20px;
    .banner {
      img {
        width: 236px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
  }

  ${fromScreen(1144)} {
    > div {
      max-height: 196px;
    }
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    .banner {
      &:nth-child(2),
      &:nth-child(3) {
        display: block;
      }
    }
  }
`;
const Banner = ({ imageHandles }) => {
  return (
    <BannerWrapper>
      {imageHandles?.map(({ alt, imgHandle }, index) => (
        <ImgWithSkeleton imgHandle={imgHandle} alt={alt} key={index} />
      ))}
    </BannerWrapper>
  );
};

const Header = ({ data }) => {
  const [, from776] = useFromScreens([0, 776]);

  return (
    <Container>
      <div className="content">
        {from776 ? (
          <Typography
            className="title"
            variant="bold/32-40"
            desktopVariant="bold/40-48"
            as="h1"
            v3
          >
            <Typography
              className="brand"
              variant="bold/32-40"
              desktopVariant="bold/40-52"
              v3
              color={colorsV2.primary100}
              as="span"
            >
              DOL English{' '}
              <LazyImage
                handle="0GYERmATmSRJWjiRPE4w"
                className="headline-icon"
              />
            </Typography>
            <br />
            Học viện <span className="highlight">Tiếng Anh Tư Duy </span>
            <br />
            đầu tiên tại Việt Nam
          </Typography>
        ) : (
          <Typography as="h1" className="title-mobile" variant="bold/32-40">
            <Typography
              className="brand"
              variant="bold/32-40"
              desktopVariant="bold/40-52"
              v3
              color={colorsV2.primary100}
              as="span"
            >
              DOL English
              <LazyImage
                handle="0GYERmATmSRJWjiRPE4w"
                className="headline-icon"
              />
              <br />
            </Typography>
            Học viện Tiếng Anh <br />
            Tư Duy đầu tiên tại <br />
            Việt Nam
          </Typography>
        )}

        <div className="description">
          {headerDescription.map((text, index) => (
            <DescriptionItem key={index} description={text} />
          ))}
        </div>
        <Link to="/gioi-thieu-linearthinking">
          <Button size="large" className="btn-action linear-btn" type="primary">
            <Typography variant="semi-bold/14-24" color={colorsV2.white100}>
              Linearthinking là gì?
            </Typography>
          </Button>
        </Link>
      </div>

      <div className="right-section">
        <Banner imageHandles={data.bannerImageHandlesV2} />
        <LazyImage
          handle="RY1uavGWQqW2aZ0GGJDo"
          className="banner-icon"
          alt="icon-banner"
        />
      </div>
    </Container>
  );
};

Header.propTypes = {
  data: PropTypes.shape({
    lines: PropTypes.arrayOf(PropTypes.string),
    imgHandle: PropTypes.arrayOf(PropTypes.string)
  })
};

const Container = styled.div`
  padding: 32px 0;
  .linear-btn {
    border-radius: 8px;
    margin-top: 20px;
  }

  .content {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-rows: max-content;
    gap: 20px;

    .title-mobile,
    .brand,
    .title {
      margin: 0;
    }
    .title {
      display: none;
    }
    .brand {
      position: relative;
      width: fit-content;
      .headline-icon {
        position: absolute;
        top: -10px;
        right: -25px;
      }
    }

    .description {
      white-space: pre-wrap;
    }
  }

  .right-section {
    position: relative;
    .banner-icon {
      position: absolute;
      top: -40px;
      right: -40px;
    }
    display: none;
  }

  ${fromScreen(466)} {
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    padding-top: 100px;
    .content {
      gap: 32px;

      .title {
        display: block;
      }
      .title-mobile {
        display: none;
      }
    }

    min-height: 688px;
    display: grid;
    grid-template-columns: 1fr 200px;
    gap: 20px;
    .right-section {
      display: block;
    }
    .highlight {
      position: relative;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        background: ${colorsV2.yellow100};
        bottom: 3px;
        left: 0;
        height: 10px;
        width: 100%;
        opacity: 0.3;
        z-index: -1;
      }
    }
  }

  ${fromScreen(1144)} {
    min-height: 768px;
    gap: 44px;
    grid-template-columns: 532px 1fr;
    .linear-btn {
      margin-top: 36px;
    }
  }
`;

export default Header;

export const GCMSHeader = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "LandingCourse_Header" }) {
            content
          }
        }
      }
    `);

    return <Header data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
