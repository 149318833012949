/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import OutlineInterfaceCloss from '@dolstaff/shared/es/icons/OutlineInterfaceCloss';
import PopupButton from '@dolstaff/shared/es/PopupButton';
import styled from 'styled-components';
import { LazyImage } from 'components/LazyImage';
import { colorsV2 } from 'style/colors-v2';
import IconCertificate from './icons/IconCertificate';
import { IconCourseArrowRight } from 'components/Icon/SVGIcons';
import ResultCard from './ResultCard';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';

const Background = styled.div`
  position: absolute;
  z-index: 0;
  opacity: 0;
  transition: all 0.3s;
  display: none;

  img {
    width: 110px;
  }

  &.bgPosition0 {
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }
  &.bgPosition1 {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
  &.bgPosition2 {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
  }
  &.bgPosition3 {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
  }

  &.bg04.bg04 {
    top: unset;
    left: unset;
    bottom: 0;
    right: 0;
    transform: translate(65%, 50%);

    img {
      width: 50px;
    }
  }

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

const Main = styled.div`
  width: 100%;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;

  .mainWrp {
    position: relative;
    z-index: 2;
    .icon-open-certificate {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 3;
      font-size: 24px;
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: -1;
      background: rgba(0, 0, 0, 0.4);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: all 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${colorsV2.white100};
      > svg {
        margin-left: 4px;
      }
    }
    &:hover {
      .overlay {
        z-index: 2;
      }
    }
  }

  &:hover {
    ${Background} {
      opacity: 1;
    }
  }

  .imgWrp {
    height: 271px;
    overflow: hidden;
    margin-bottom: 1em;
    position: relative;
    ${fromScreen(776)} {
      height: 220px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      display: block;
      object-fit: cover;
    }
    .image {
      height: 100%;
    }
    .overlay {
      border-radius: 8px;
    }
  }

  .title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #2d3742;
    line-height: 24px;
  }

  .occupation {
    color: ${colorsV2.black80};
  }

  .label {
    font-size: 14px;
    color: #7a869a;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.04em;
    margin: 0;
  }

  .band {
    color: ${colorsV2.primary100};
    text-align: right;
  }
`;

const Body = styled.div`
  position: relative;
  display: flex;
  .bodyLeft {
    padding-right: 8px;
    flex: 1;
  }
  .bodyRight {
    display: flex;
    align-items: center;
    .label {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      margin-left: 4px;
    }
  }
`;

const BtnWrp = styled.div`
  position: absolute;
  right: -1em;
  top: -1em;
  z-index: 2;
`;
const CloseBtn = styled.button`
  padding: 12px;
  background-color: white;
  color: black;
  border: 0;
  outline: none;
  cursor: pointer;
  top: 3px;
  position: relative;
`;

const PopupButtonWrapper = styled(PopupButton)``;

const Item = props => {
  const {
    bangVangAvatar,
    bangVangCertificate,
    bangVangDisplayName,
    // bangVangListed,
    bangVangOccupation,
    overall
  } = props;

  return (
    <PopupButtonWrapper
      hideCloseButton
      hideOnBackdropClick
      isCentered
      zIndex={600}
      style={{
        width: '100%',
        maxWidth: '500px',
        padding: '14px 16px'
      }}
      trigger={({ toggleContent }) => (
        <Main
          role="button"
          onClick={() => {
            if (bangVangCertificate) {
              toggleContent();
            }
          }}
        >
          <div className="mainWrp">
            {bangVangCertificate && (
              <div className="icon-open-certificate">
                <IconCertificate />
              </div>
            )}
            <div className="imgWrp">
              <div className="overlay">
                Xem result <IconCourseArrowRight />
              </div>
              <LazyImage
                className="image"
                imgProps={{
                  alt: 'bang-vang-avatar'
                }}
                handle={bangVangAvatar}
                width={350}
              />
            </div>

            <Body>
              <div className="bodyLeft">
                <Typography variant="semi-bold/16-24" as="h6">
                  {bangVangDisplayName}
                </Typography>
                <Typography variant="regular/14-20" className="occupation">
                  {bangVangOccupation}&nbsp;
                </Typography>
              </div>
              <div className="bodyRight">
                <Typography variant="semi-bold/40-48" className="band">
                  {overall}
                </Typography>
                <h6 className="label">
                  IELTS
                  <br />
                  Overall
                </h6>
              </div>
            </Body>
          </div>
        </Main>
      )}
    >
      {({ toggleContent }) => (
        <div style={{ position: 'relative' }}>
          <BtnWrp>
            <CloseBtn onClick={() => toggleContent()}>
              <OutlineInterfaceCloss size={24} />
            </CloseBtn>
          </BtnWrp>
          <ResultCard
            full
            name={bangVangDisplayName}
            occupation={bangVangOccupation}
            band={overall}
            certificate={bangVangCertificate}
            {...props}
          />
        </div>
      )}
    </PopupButtonWrapper>
  );
};

Item.propTypes = {
  name: PropTypes.string,
  profilePicture: PropTypes.shape({}),
  certificate: PropTypes.shape({}),
  occupation: PropTypes.string,
  band: PropTypes.string
};

export default Item;
