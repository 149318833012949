import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Item from './Item';
import { fromScreen } from '../../utils/media-query/responsive.util';

const List = styled.div`
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 24px;

  .itemWrp {
    width: 100%;
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
  }

  ${fromScreen(1144)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ListItem = ({ hallOfFameList }) => {
  return (
    <List>
      {hallOfFameList.map(i => (
        <div className="itemWrp" key={i.id}>
          <Item {...i} />
        </div>
      ))}
    </List>
  );
};

ListItem.propTypes = {
  hallOfFameList: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ListItem;
