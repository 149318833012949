import { graphql, useStaticQuery } from 'gatsby';

const queryCourses = graphql`
  {
    gcms {
      jsonContaniers(where: { name: "HOME_Courses_List" }) {
        content
      }
    }
  }
`;
export const useCoursesGroupData = () => {
  const data = useStaticQuery(queryCourses);
  return data.gcms.jsonContaniers[0].content;
};
