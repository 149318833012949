import * as React from 'react';

function IconCheckmark() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3016 5.20312L9.00492 11.4998L5.85742 8.35146"
        stroke="#D14242"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 9C16.5 13.1425 13.1425 16.5 9 16.5C4.8575 16.5 1.5 13.1425 1.5 9C1.5 4.8575 4.8575 1.5 9 1.5C10.2108 1.5 11.3508 1.79417 12.3633 2.30333"
        stroke="#D14242"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default React.memo(IconCheckmark);
