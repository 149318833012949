import * as React from 'react';

function IconCertificate(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0015 11.7498C16.4819 11.7498 15.25 12.9816 15.25 14.5012C15.25 16.0208 16.4819 17.2527 18.0015 17.2527C19.521 17.2527 20.7529 16.0208 20.7529 14.5012C20.7529 12.9816 19.521 11.7498 18.0015 11.7498ZM13.75 14.5012C13.75 12.1532 15.6534 10.2498 18.0015 10.2498C20.3495 10.2498 22.2529 12.1532 22.2529 14.5012C22.2529 16.8492 20.3495 18.7527 18.0015 18.7527C15.6534 18.7527 13.75 16.8492 13.75 14.5012Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.002 16.6243C16.4162 16.6243 16.752 16.9601 16.752 17.3743V20.7907L17.6674 20.333C17.8785 20.2274 18.1271 20.2274 18.3382 20.333L19.2536 20.7907V17.3743C19.2536 16.9601 19.5894 16.6243 20.0036 16.6243C20.4178 16.6243 20.7536 16.9601 20.7536 17.3743V22.0042C20.7536 22.2641 20.619 22.5055 20.3979 22.6422C20.1768 22.7788 19.9007 22.7913 19.6682 22.675L18.0028 21.8423L16.3374 22.675C16.1049 22.7913 15.8288 22.7788 15.6077 22.6422C15.3865 22.5055 15.252 22.2641 15.252 22.0042V17.3743C15.252 16.9601 15.5877 16.6243 16.002 16.6243Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99693 3.74634C4.30568 3.74634 3.74609 4.30592 3.74609 4.99717V19.003C3.74609 19.6943 4.30568 20.2538 4.99693 20.2538H13.0003C13.4145 20.2538 13.7503 20.5896 13.7503 21.0038C13.7503 21.4181 13.4145 21.7538 13.0003 21.7538H4.99693C3.47725 21.7538 2.24609 20.5227 2.24609 19.003V4.99717C2.24609 3.4775 3.47725 2.24634 4.99693 2.24634H18.0023C19.522 2.24634 20.7532 3.4775 20.7532 4.99717V7.99842C20.7532 8.41263 20.4174 8.74842 20.0032 8.74842C19.589 8.74842 19.2532 8.41263 19.2532 7.99842V4.99717C19.2532 4.30592 18.6936 3.74634 18.0023 3.74634H4.99693Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24805 6.99805C6.24805 6.58383 6.58383 6.24805 6.99805 6.24805H16.0018C16.416 6.24805 16.7518 6.58383 16.7518 6.99805C16.7518 7.41226 16.416 7.74805 16.0018 7.74805H6.99805C6.58383 7.74805 6.24805 7.41226 6.24805 6.99805Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24805 10.9998C6.24805 10.5855 6.58383 10.2498 6.99805 10.2498H10.9997C11.4139 10.2498 11.7497 10.5855 11.7497 10.9998C11.7497 11.414 11.4139 11.7498 10.9997 11.7498H6.99805C6.58383 11.7498 6.24805 11.414 6.24805 10.9998Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24805 15.0012C6.24805 14.587 6.58383 14.2512 6.99805 14.2512H9.9993C10.4135 14.2512 10.7493 14.587 10.7493 15.0012C10.7493 15.4154 10.4135 15.7512 9.9993 15.7512H6.99805C6.58383 15.7512 6.24805 15.4154 6.24805 15.0012Z"
        fill="white"
      />
    </svg>
  );
}

export default React.memo(IconCertificate);
