import * as React from 'react';

import cl from 'classnames';
import format from 'date-fns/format';
import vi from 'date-fns/locale/vi';
import { Link, StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import take from 'lodash/take';
import styled from 'styled-components';

import { LazyImage } from 'components/LazyImage';
import { HeadlineIcon } from 'components/HeadlineIcon';
import Button from 'components/Button';
import { colorsV2 } from 'style/colors-v2';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { IconCourseArrowRight } from 'components/Icon/SVGIcons';
import { withHomePageSection } from 'components/withHomePageSection';
import { fromScreen } from 'utils/media-query/responsive.util';

import { MasonryLayout } from '../Layout/MasonryLayout';
import { Typography } from 'components/DesignSystemV2/Typography';
import IconColorFB from './icons/IconColorFB';

/* eslint-disable react/prop-types */
const ImgAvatar = styled(LazyImage)`
  max-width: 48px;
  max-height: 48px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Main = styled.div`
  .section-title {
    margin-bottom: 24px;
  }
  > a {
    width: 100%;
    display: block;
    margin-top: 24px;
    text-align: center;
    .cta-button {
      width: fit-content;
      background: transparent;
      border-radius: 8px;
    }
  }
  ${fromScreen(776)} {
    .section-title {
      margin-bottom: 40px;
    }
    > a {
      display: none;
    }
  }
`;

const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 20px;
  grid-column-gap: 16px;
  align-items: center;
  .card-review-header-avatar {
    img {
      border-radius: 50%;
    }
  }
  .card-review-header-info {
    .date {
      color: ${colorsV2.black80};
    }
  }
`;
const ImageSection = styled.div`
  img {
    width: 100%;
    height: 220px;
    object-fit: cover;
  }
`;
const DescriptionSection = styled.div``;
const ClamppedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.text-only {
    -webkit-line-clamp: 15;
  }
`;
const ReviewCardWrapper = styled.div`
  position: relative;
  cursor: pointer;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray20};
  border-radius: 8px;
  ${HeaderSection} {
    padding: 16px 16px 0 16px;
  }
  ${ImageSection} {
    margin-top: 16px;
  }
  ${DescriptionSection} {
    margin-top: 16px;
    padding: 0 16px 16px 16px;
  }

  .hover-state {
    border-radius: 8px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    position: absolute;
    background: rgb(0 0 0 / 40%);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    transition: 0.2s ease;
    color: ${colorsV2.white100};
    .see-more {
      display: flex;
      align-items: center;
    }
  }

  &:hover {
    .hover-state {
      visibility: visible;
      opacity: 1;
    }
  }
`;
const ReviewCard = ({
  linkAvatar,
  fullName,
  dateFormatted,
  reviewContent,
  linkReview,
  linkImage
}) => {
  return (
    <ReviewCardWrapper>
      <HeaderSection>
        <div className="card-review-header-avatar">
          <ImgAvatar
            imgProps={{
              alt: 'student-review'
            }}
            width={48}
            height={48}
            handle={linkAvatar}
          />
        </div>
        <div className="card-review-header-info">
          <Typography variant="semi-bold/14-20" className="title">
            {fullName}
          </Typography>
          <Typography variant="regular/14-20" className="date">
            {dateFormatted}
          </Typography>
        </div>
        <IconColorFB width={20} height={20} />
      </HeaderSection>
      {linkImage && (
        <ImageSection>
          <LazyImage
            imgProps={{
              alt: 'student-review'
            }}
            handle={linkImage}
          />
        </ImageSection>
      )}
      <DescriptionSection>
        <ClamppedText className={cl({ 'text-only': !linkImage })}>
          <Typography variant="regular/14-20">{reviewContent}</Typography>
        </ClamppedText>
      </DescriptionSection>
      <Link
        href={linkReview}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <div className="hover-state">
          <Typography
            variant="semi-bold/14-20"
            className="see-more"
            color={colorsV2.white100}
          >
            Xem review <IconCourseArrowRight />
          </Typography>
        </div>
      </Link>
    </ReviewCardWrapper>
  );
};

// const limitReview = 6;

const StudentCourseReviews = ({ data, columns = 3 }) => {
  const { isMobile } = useDeviceDetect3();
  let limitReview;
  if (isMobile) {
    limitReview = 3;
  } else {
    limitReview = 6;
  }
  const reviewPaged = take(data, 1 * limitReview);
  return (
    <Main id={'course-landing-student-reviews'}>
      <HeadlineIcon
        iconHandle="0GYERmATmSRJWjiRPE4w"
        url="/hall-of-fame"
        className="section-title"
        isHomePage
      >
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Review học viên khóa IELTS tại DOL
        </Typography>
      </HeadlineIcon>
      <MasonryLayout breakpointCols={columns}>
        {reviewPaged.map(review => {
          const {
            id,
            date,
            fullName,
            linkReview,
            linkAvatar,
            linkCover,
            reviewContent
          } = review.node;
          const dateFormatted = format(new Date(date), 'MM/yyyy', {
            locale: vi
          });
          return (
            <ReviewCard
              key={id}
              dateFormatted={dateFormatted}
              fullName={fullName}
              linkAvatar={linkAvatar}
              linkImage={linkCover}
              linkReview={linkReview}
              reviewContent={reviewContent}
            />
          );
        })}
      </MasonryLayout>
      <Link to="/hall-of-fame">
        <Button outline className="cta-button">
          <Typography
            style={{ color: colorsV2.primary100 }}
            variant="semi-bold/14-24"
          >
            Xem review học viên
          </Typography>
        </Button>
      </Link>
    </Main>
  );
};

const studentOnlineCourseFacebookReviewsQuery = graphql`
  query studentOnlineCourseFacebookReview {
    allGoogleSpreadsheetDolLandingPageReviewAll(
      filter: { listing: { eq: "listed" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          linkReview
          linkAvatar
          linkCover
          fullName
          date
          reviewContent
          listing
          classType
          program
          order
        }
      }
    }
  }
`;

const StudentReviewsMain = ({ program, bubble, columns }) => {
  return (
    <StaticQuery
      query={studentOnlineCourseFacebookReviewsQuery}
      render={data => {
        const reviews =
          data.allGoogleSpreadsheetDolLandingPageReviewAll.edges.filter(
            item => {
              let isValidClassType = item.node.program === program;
              const isNotBlank =
                (get(item, 'node.reviewContent', '') || '').trim().length > 0;
              if (program === 'all') isValidClassType = true;
              return isValidClassType && item.node.linkAvatar && isNotBlank;
            }
          );

        return (
          <StudentCourseReviews
            bubble={bubble}
            data={reviews}
            columns={columns}
          />
        );
      }}
    />
  );
};

export default withHomePageSection(StudentReviewsMain);
