import * as React from 'react';

function IconSend({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86719 8C1.86719 7.65482 2.14701 7.375 2.49219 7.375H7.06982C7.415 7.375 7.69482 7.65482 7.69482 8C7.69482 8.34518 7.415 8.625 7.06982 8.625H2.49219C2.14701 8.625 1.86719 8.34518 1.86719 8Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08791 1.58142C1.9551 1.515 1.79569 1.53254 1.68049 1.62624C1.56533 1.71991 1.5157 1.87234 1.55362 2.01585C1.55363 2.0159 1.5536 2.01581 1.55362 2.01585L3.09633 7.8402C3.1241 7.94507 3.1241 8.05538 3.09633 8.16025L1.55365 13.9845C1.55364 13.9845 1.55367 13.9844 1.55365 13.9845C1.51574 14.128 1.56533 14.2805 1.68049 14.3742C1.79569 14.4679 1.9551 14.4854 2.08791 14.419L14.2532 8.33797C14.3811 8.27404 14.462 8.14325 14.462 8.00023C14.462 7.8572 14.3812 7.72645 14.2532 7.66252L2.08791 1.58142ZM2.64692 0.463389C2.07474 0.177279 1.388 0.252844 0.891715 0.656535C0.395392 1.06025 0.181581 1.71727 0.345246 2.33577L1.84561 8.00023L0.345284 13.6645C0.181619 14.283 0.395392 14.9402 0.891715 15.3439C1.38804 15.7476 2.07483 15.8232 2.64704 15.537L14.812 9.4561C15.3636 9.18047 15.712 8.61682 15.712 8.00023C15.712 7.38366 15.3636 6.82002 14.8121 6.54439C14.8121 6.5444 14.812 6.54438 14.8121 6.54439L2.64692 0.463389C2.64689 0.46337 2.64696 0.463408 2.64692 0.463389Z"
        fill={color}
      />
    </svg>
  );
}

export default React.memo(IconSend);
