/* eslint-disable react/prop-types */

import * as React from 'react';

import 'reactjs-popup/dist/index.css';

import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components/macro';
import { Link } from 'gatsby';

import { useCoursesGroupData } from './data/useCoursesGroupData';
import CourseButton from './CourseButton';
import { fromScreen } from 'utils/media-query/responsive.util';
import { withHomePageSection } from 'components/withHomePageSection';
import { LazyImage } from 'components/LazyImage';
import ButtonText from 'components/ButtonText';

const ROUTE_COURSE = {
  'Khóa IELTS cơ bản': 'ielts-co-ban',
  'Khóa IELTS nâng cao': 'ielts-nang-cao',
  'Khóa IELTS cấp tốc': 'ielts-cap-toc',
  'Khóa TA giao tiếp': 'tieng-anh-giao-tiep',
  'IELTS Online': 'hoc-ielts-online',
  SAT: 'sat',
  TOEIC: 'toeic'
};

const groupCoursesForDisplay = groupCourses =>
  groupCourses
    .map(courseGroupMapping => {
      return {
        name: courseGroupMapping.name
      };
    })
    .reduce((mergedObj, current) => {
      return {
        ...mergedObj,
        [current.name]: current.courses
      };
    }, {});

const ListCourse = styled.div``;

const Wrapper = styled.div`
  padding: 32px 0;
  background: ${colorsV2.white100};
  border-bottom: 1px solid ${colorsV2.gray20};

  position: relative;
  .button-text {
    margin-top: 34px;
    margin: 34px auto 0;
  }
  .course-icon {
    display: none;
  }
  ${ListCourse} {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .course-button {
      display: flex;
      justify-content: center;
      padding: 10px;
      .icon-wrapper {
        display: none;
      }
      > p {
        font-size: 16px;
      }
    }
  }

  ${fromScreen(776)} {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 20px;
    margin-top: -100px;
    .course-icon {
      display: block;
      position: absolute;
      top: -80px;
      left: 35%;
      transform: translate(-50%, 0) rotate(180deg);
    }

    ${ListCourse} {
      grid-template-columns: repeat(3, 172px);
      gap: 20px;
      justify-content: center;
      .course-button {
        justify-content: flex-start;
        .icon-wrapper {
          display: block;
        }
        padding: 10px 0 10px 12px;
        > p {
          font-size: 16px;
        }
      }
    }
    padding: 28px 0;
  }

  ${fromScreen(1144)} {
    .course-icon {
      top: -80px;
      left: 42%;
    }
    margin-top: -150px;
    ${ListCourse} {
      gap: 32px;
      grid-template-columns: repeat(4, 1fr);
      .course-button {
        display: grid;
        justify-content: flex-start;

        .icon-wrapper {
          display: block;
        }
        padding: 16px 18px;
        > p {
          font-size: 20px;
        }
      }
    }
    padding: 40px 68px;
  }
`;

export const getName = name => {
  if (name.includes('Khóa khác')) {
    return 'IELTS Online và Phát Âm';
  }

  const hasPrefix = name.startsWith('Khóa');

  if (hasPrefix) {
    return name.substr(5).trim();
  }

  return name;
};

export const CoursesLayout = ({ groupCourses, CourseDisplay }) => {
  const sortedCourses = groupCoursesForDisplay(groupCourses);
  return <CourseDisplay courses={sortedCourses} groupCourses={groupCourses} />;
};

export const TabCourses = ({ courses }) => {
  const names = Object.keys(courses);
  names.splice(3, 0, 'IELTS Online');

  return (
    <>
      <Wrapper>
        <ListCourse>
          {names.map((name, key) => (
            <Link to={ROUTE_COURSE[name]} key={key}>
              <CourseButton content={getName(name)} />
            </Link>
          ))}
        </ListCourse>
        <Link to="dam-bao-dau-ra">
          <ButtonText
            content="Chính sách đảm bảo đầu ra tại DOL English"
            type="primary"
          />
        </Link>

        <LazyImage
          handle="pZtoUY3mStuWmaQ5lBx6"
          className="course-icon"
          alt="icon"
        />
      </Wrapper>
    </>
  );
};

export const GCMSCourses = withHomePageSection(() => {
  const { desktopGroupCourses } = useCoursesGroupData();
  return (
    <CoursesLayout
      groupCourses={desktopGroupCourses}
      CourseDisplay={TabCourses}
    />
  );
});
